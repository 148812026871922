/**=====================
     47. Footer CSS Start
==========================**/
.footer {
    background-color: $footer_bg_color;
    box-shadow: $footer_box_shadow;
    padding: 15px;
    bottom: 0;
    left: 0;
    margin-left: 265px;
    transition: 0.5s;
    &.footer-dark {
        background-color: $footer_dark_color;
        p {
            color: $white;
        }
    }

    &.close_icon {
        margin-left: 60px;
        width: calc(100% - 60px);
    }
}

.footer-fix {
    width: calc(100% - $sidebar-compact-width);
    position: fixed;
}
/**=====================
     47. Footer CSS Ends
==========================**/
